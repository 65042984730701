/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react'
import { Link } from 'gatsby'
import { useForm } from '@formcarry/react';
import SEO from '../components/seo'
import ContactInfo from '../utils/contactInfo'
import { FormContain, BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight } from '../utils/copperTypography';

const inline100 = {
  display: 'inline-block',
  width: '100%'
}
const styleSortable = {
  paddingTop: '20px',
//  backgroundColor: 'pink'
}

const noticeSuccess = {
  backgroundColor: 'rgba(0,0,0,0.04)',
  padding: '20px',
}


const WarrantyFormPage = () => { 
  

  const {state, submit} = useForm({
    id: 'zU97ISCiWfHr'
  });
    

  return (
  <BasicPageContain> 
    <SEO title="Warranty" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>30-Day Unconditional<br />Money-Back Guarantee</h1>
      <Grid>
        <GridLeft>
          <h3>Contact information</h3>
          <ContactInfo />
        </GridLeft>
        <GridRight>


          { state.submitted &&
            <div className='notice-sucess' style={noticeSuccess}>
              <h3>We've received your warranty registration</h3>
              <h1>Thank you!</h1>
              <p className="wide">Please check your inbox to confirm your address!</p>
            </div>
          }

          { !state.submitted && <>
          <h3>Register your purchase</h3>
          <p className="wide">We back all of our products with a money-back guarantee. If you are not completely satisfied with this product for any reason, CopperCompression.com (a Copper Compression, LLC site) will gladly refund your purchase price, excluding shipping and handling charges, within 30 days of purchase.</p>
          <FormContain>
            <form onSubmit={submit} id="quick-contact">

              <div className="elcontainer normal inline-label-left left-aligned">
                <div className="form-header">
                  <h2>Fill in the below fields to register your product</h2>
                </div>

                <div className="sortables" style={styleSortable}>

                  <h3>Your Information</h3>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="First Name" style={inline100}>
                    <label htmlFor="dataFname">First Name</label>
                    <input type="text" placeholder="" id="dataFname" name="First Name" />
                  </div>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Last Name" style={inline100}>
                    <label htmlFor="dataLname">Last Name</label>
                    <input type="text" placeholder="" id="dataLname" name="Last Name" />
                  </div>

                  <div className="formEl fieldtype-input required" data-validation-type="1" data-label="Email" style={inline100}>
                    <label htmlFor="dataEmail">Email<span className="indicator required">*</span></label>
                    <input type="text" placeholder="" id="dataEmail" name="Email"/>
                  </div>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Phone" style={inline100}>
                    <label htmlFor="dataPhone">Phone</label>
                    <input type="text" placeholder="" id="dataPhone" name="Phone" />
                  </div>

                  <h3>Product Information</h3>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Product Name" style={inline100}>
                    <label htmlFor="dataProductname">Product Name</label>
                    <input type="text" placeholder="" id="dataProductname" name="Product Name" />
                  </div>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Product Size" style={inline100}>
                    <label htmlFor="dataProductsize">Product Size (if applicable)</label>
                    <input type="text" placeholder="" id="dataProductsize" name="Product Size" />
                  </div>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Date of Purchase" style={inline100}>
                    <label htmlFor="dataPurchasedate">Date of Purchase</label>
                    <input type="text" placeholder="" id="dataPurchasedate" name="Date of Purchase" />
                  </div>

                  <div className="formEl fieldtype-input" data-validation-type="1" data-label="Order Number" style={inline100}>
                    <label htmlFor="dataNumber">Order Number</label>
                    <input type="text" placeholder="" id="dataNumber" name="Order Number" />
                  </div>


                  <div className="formEl fieldtype-radio" data-validation-type="1" data-label="Where did you make your purchase?" style={inline100}>
                    <h3>Where did you make your purchase?</h3>
                    <div className="option-container">
                      <label className="radio"><input className="radio-input" type="radio" alt="" name="Purchased From" value="CopperCompression.com" />CopperCompression.com</label><br />
                      <label className="radio"><input className="radio-input" type="radio" alt="" name="Purchased From" value="Amazon" />Amazon.com</label><br />
                      <label className="radio"><input className="radio-input" type="radio" alt="" name="Purchased From" value="Somewhere Else" />Somewhere Else</label>
                    </div>
                  </div>

                  <div className="submit-container">
                    <input type="submit" value="Register" className="btn btn-submit" />
                  </div>

                </div>
              </div>
            </form>
          </FormContain>
          </>
          }

          <p className="wide">If you choose to return or exchange your product you must email help@coppercompression.com We will provide shipping instructions at that time. We will need your order number, name, address, phone number, product to be returned. We will also need a copy of your invoice when you return the product.</p>
          <Link to="/">Go back to the homepage</Link>
        </GridRight>

      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
)}

export default WarrantyFormPage
