import React from 'react'

const ContactInfo = () => 
  < >
    <p>Copper Compression</p>
    <p>
      {/* (212) 239-8615<br /><br /> */}
      <a href="mailto:help@coppercompression.com">help@coppercompression.com</a>
    </p>
    <p>

      If your question is not answered here please <a href="help@coppercompression.com">email</a> for help. We look forward to helping you find an answer!
    </p>
  </ >

export default ContactInfo
